:root{
    --main-theme-color:#F64A00;
    --main-text-color:#656D78;
    
}


@font-face{
    font-family: "Inter-UI-Regular";
    src:local('Inter-UI-Regular') url(./fonts/Inter-UI-Regular.ttf) format('ttf');
}


*:focus {
    outline: none;
}

.button {
    cursor: pointer;
}

.button.button-black, .button.button-white {
    
    border-radius: 27px;
    
    width: 140px;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.button.button-black {
    border: 1px solid white;
    background: black;
    color: white;
}

.button.button-white {
    background: white;
    border: 1px solid black;
    color: black;
}

.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-bottom: 20px solid white;

  }

  .notification {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
}

.notification .notification-blue{
    background: blue;
}

.cursor-pointer {
    cursor: pointer;
}

.avatar{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	vertical-align: middle;
    align-content: center;
    margin: 8px;
}

.avatarBig{
	width: 120px;
	height: 120px;
	border-radius: 50%;
	vertical-align: middle;
    align-content: center;
    margin: 8px;

}

