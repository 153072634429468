#dimmer{
    background:rgba(252,252,252, 0.9);
    /* opacity: 0.6; */
    display: grid;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
}

/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
.spinner {
    margin: 250px auto 0;
    width: 70px;
    text-align: center;
    
  }
  
  .spinner > div {
    opacity: 1 !important;
    z-index: 999;
    width: 18px;
    height: 18px;
    background-color:var(--main-theme-color);
    margin-left: 5px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }