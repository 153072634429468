.primary {
    background: var(--main-theme-color) !important;
}
.primary span, .lower-opacity span{
    color:white !important;
}

.inactive{
    background:#F8F8F9 !important;
    cursor: default !important;
}
.inactive > span {
    opacity: 0.5 !important;
}

.black{
    background: #172029 !important;
}

.black span{
    color:white !important;
}


.btn{
    opacity: 0.9;
    border-radius: 26px;
    border:none;
    cursor: pointer;
    background: white;
    height:52px;
    min-width: 120px;
}
.lower-opacity {
    background: var(--main-theme-color);
    color: white;
    opacity: 0.8;
}


.btn-span{
    color:#2A2E43;
    margin-left:31px;
    margin-right:31px;
    font-size: 16px;
}

.btn:hover{
    opacity: 1;
}

.btn:active{
    opacity: 1;
}

.btn:focus{
    outline:none;
}
