
@font-face {
    font-family: interRegular;
    src: url("fonts/Inter-UI-Regular.woff2");
}

@font-face {
font-family: interMedium;
src: url("fonts/Inter-UI-Medium.woff2");
}
  
body * {
    font-family: "Inter", sans-serif;
    /* font-size: 18px; */

}

body{
    margin: 0px;
    zoom:80%;
}

/* @-moz-document url-prefix() {
    body {
    height: 100vh;
    margin:-10vh -12.5vw;
    transform: scale(0.8);
    }
  } */

@media only screen and (max-width:1280px){
    body{
        /* transform: scale(0.7); */
        zoom:80%;
    }
}

/*Hitta breaking styles*/
 /* body * {
    border: 1px red solid !important;
    background: black !important;
    color: greenyellow !important;
}  */